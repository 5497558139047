<template>
    <div class="content">
        <MyBreads>分店資訊</MyBreads>
        <div v-if="storeList && storeList.length>0">
            <el-row type="flex" justify="start" style="flex-wrap: wrap">
                <el-col :lg="12" :xs="24" :md="12" :key="index" v-for="(item,index) in storeList">
                    <el-card style="margin: 10px;" shadow="hover">
                        <div class="el-card__header">
                            <img :src="item.logo" style="max-width: 100%;max-height: 100%;border-radius: 3px">
                        </div>
                        <div class="el-card__body" style="display: flex;flex-direction:column;align-items: start">
                            <p class="store-item" style="display: flex;justify-content: start">
                                <strong>
                                    <span style="font-size: 20px;">{{item.title}}</span>
                                    <span v-if="item.titleNext" style="color: #54595F;font-size: small;margin-left: 10px">({{item.titleNext}})</span>
                                </strong>
                            </p>
                            <p class="store-item" v-html="item.description"></p>
                            <p class="store-item">{{item.address}}</p>
                            <p class="store-item">聯絡人: {{item.contact}}</p>
                            <p class="store-item">電話: {{item.phone}}</p>
                            <p class="store-item">營業時間:</p>
                            <p class="store-item">平日: {{item.bhStNormal}}~{{item.bhEdNormal}}</p>
                            <p class="store-item">假日: {{item.bhStHoliday}}~{{item.bhEdHoliday}}</p>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
        <div v-else v-show="!emptFlg">
            <el-skeleton/>
        </div>
        <div v-show="emptFlg">
            <el-empty :image-size="100" description="暫時沒有資料">
            </el-empty>
        </div>
    </div>
</template>

<script>
    import MyBreads from '@/components/MyBreads.vue';
    import {GetStoreList} from '@/request/api';

    export default {
        name: '',
        components: {MyBreads},
        data() {
            return {
                limit: 10,
                page: 1,
                keywords: '',
                storeList: [],
                emptFlg: false
            }
        },
        methods: {
            getStoreList() {
                let _this = this;
                const loading = this.$loading({
                    lock: true,
                    text: "加載中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.5)"
                });
                GetStoreList({limit: this.limit, page: this.page, keywords: this.keywords}).then(res => {
                    loading.close();
                    if (res.code == 200) {
                        this.storeList = res.data.list;
                        if (this.storeList.length <= 0) {
                            _this.emptFlg = true;
                        } else {
                            _this.emptFlg = false;
                        }
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    loading.close();
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            }
        }, mounted() {
            this.getStoreList();
        }
    }
</script>
<style lang="less" scoped>
    .store-item {
        margin: 10px 1%;
        padding-bottom: 10px;

        &:last-child {
            border-bottom-width: 0px;
            margin-bottom: 0px;
        }
    }
</style>
